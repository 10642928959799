.rbc-event-content {
  white-space: normal !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-agenda-view {
  color: white;
}