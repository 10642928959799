.details-content {
  display: block;
  font-size: 0.8rem;
  max-height: 4.5rem;

  &__label {
    // TODO: add specific css for label
  }

  &__range {
    // TODO: add specific css for range
  }
}