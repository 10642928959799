.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  .not-found-content {
    text-align: center;
    padding: 1.2rem;
    background-color: #ffffff;
    border-radius: 0.7rem;

    h1 {
      color: #e74c3c;
    }
  }

  .not-found-image {
    width: 50%;
    margin: 1.2rem 0;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

